import React, { Component } from "react";
import SearchForm from "../search/index.jsx";
import Results from "../results/index";
import PlaceDetailsModal from "../place-details";
import * as util from "../util";
import "./styles/base.scss";
import * as CITIES from "../constants/cities";
import {
  CATEGORIES,
  MILES_TO_METERS,
  BOONE_API,
  BOONE_API_SEARCH,
  BOONE_API_CATEGORICAL_PLACES,
  RT_API,
  RT_API_USERS,
  DEFAULT_CITY_CATEGORY,
  VIEW_TYPE_LIST,
  VIEW_TYPE_MAP
} from "../constants/base";

import { formatPlaceProps } from "../place";

class Explorer extends Component {
  viewState = {};

  state = {
    params: {}, // search params - object keys should match url param keys
    places: [],
    modalOpen: false,
    modalPlace: "",
    totalResults: 0,
    placeDetails: {}
  };

  CITIES = CITIES[this.props.country];
  CATEGORIES = CATEGORIES[this.props.country];

  DEFAULT_CITY_IDX = DEFAULT_CITY_CATEGORY[this.props.country].city;
  DEFAULT_CATEGORY_IDX = DEFAULT_CITY_CATEGORY[this.props.country].category;

  componentDidMount() {
    const fidelityParam = {
      min_fidelity: this.props.params.canonical_place_ids ? 1 : 4
    };

    if (this.props.showSearch) {
      this.setState(prevState => ({
        params: {
          offset: 0,
          ...prevState.params,
          ...this.props.params,
          ...this.getCenterRadiusTags(),
          ...this.getCityCategory(),
          ...fidelityParam
        }
      }));
    } else {
      this.setState(prevState => ({
        params: {
          offset: 0,
          ...prevState.params,
          ...this.props.params,
          ...fidelityParam
        }
      }));
    }
  }

  async componentDidUpdate(previousProps, previousState) {
    if (previousState.params !== this.state.params) {
      const results = await this.search();
      if (results) {
        if (results.errors) {
          results.errors.forEach(e =>
            console.error("[Boone Explorer]:", e.message)
          );
        } else if (results.meta) {
          // If place ids were provided, sort results in order of ids provided
          if (previousProps.params.canonical_place_ids) {
            const placeIds = previousProps.params.canonical_place_ids.split(
              ","
            );

            const resultsMap = results.data.reduce((prev, curr) => {
              prev[curr.id] = curr;
              return prev;
            }, {});

            const sortedResults = placeIds
              .map(id => resultsMap[id])
              .filter(place => Boolean(place));

            this.setState({
              places: [...sortedResults],
              totalResults: results.meta.total
            });

            if (placeIds.length !== sortedResults.length) {
              console.error(
                `[Boone Explorer]: ${
                  placeIds.length
                } "data-places" (place ids) were set, but search returned ${
                  results.meta.total
                } results, please check place ids for errors`
              );
            }
          } else {
            this.setState(prevState => ({
              places: [...prevState.places, ...results.data],
              totalResults: results.meta.total
            }));
          }
        }
      } else {
        console.debug(
          '[Boone Explorer]: "results" is undefined in component "Explorer"'
        );
      }
    }
  }

  async search() {
    const path = "?" + util.formatURLParams(this.state.params);
    return await this.booneAPIGetRequest(BOONE_API_SEARCH + path);
  }

  newSearch(idxCategory, idxCity) {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        ...this.getCenterRadiusTags(idxCategory, idxCity),
        offset: 0
      },
      ...this.getCityCategory(idxCategory, idxCity),
      places: []
    }));
  }

  loadMore() {
    this.setState(prevState => {
      const { offset = 0, limit = 0, ...params } = prevState.params;
      const t = offset + limit;
      return {
        params: {
          ...params,
          limit,
          offset: t < prevState.totalResults ? t : prevState.totalResults
        }
      };
    });
  }

  getCityCategory(
    idxCategory = this.DEFAULT_CATEGORY_IDX,
    idxCity = this.DEFAULT_CITY_IDX
  ) {
    const category = this.CATEGORIES[idxCategory][0]
      .replace("&", "and")
      .toLowerCase()
      .replace(" rv", " RV");
    const t = this.CITIES[idxCity][0];
    const city =
      this.props.country === "us" ? t.substring(0, t.lastIndexOf(",")) : t;
    return { category, city };
  }
  f;

  getCenterRadiusTags(
    idxCategory = this.DEFAULT_CATEGORY_IDX,
    idxCity = this.DEFAULT_CITY_IDX
  ) {
    const center = this.CITIES[idxCity][1].toString();
    const tags = this.CATEGORIES[idxCategory][1].join(",");
    const radius = MILES_TO_METERS * this.CITIES[idxCity][2];
    return { center, tags, radius };
  }

  showModal(placeId) {
    if (this.state.placeDetails && this.state.placeDetails[placeId]) {
      this.setState({ modalOpen: true, modalPlace: placeId });
    } else {
      this.placeDetails(placeId).then(resultsJson => {
        let placeDetails = this.state.placeDetails || {};
        placeDetails[placeId] = resultsJson.data;
        this.setState({
          modalOpen: true,
          placeDetails: placeDetails,
          modalPlace: placeId
        });
      });
    }
  }

  hideModal() {
    this.setState({ modalOpen: false });
  }

  placeDetails(id) {
    return this.booneAPIGetRequest(BOONE_API_CATEGORICAL_PLACES + "/" + id);
  }

  booneAPIGetRequest(endPoint) {
    return fetch(BOONE_API + endPoint, {
      headers: { "RT-ORG-APP-CLIENT-ID": this.props.clientId },
      method: "GET"
    }).then(response => response.json());
  }

  userProfile(userName) {
    return fetch(RT_API + RT_API_USERS + "/" + userName + "/profile", {
      method: "GET"
    }).then(response => response.json());
  }

  render() {
    const placeAttrsStr = this.props.params.canonical_place_ids;
    const maxResults = this.props.maxResults
      ? parseInt(this.props.maxResults)
      : false;
    const limit = this.props.params.limit ? this.props.params.limit : false;
    const places = maxResults
      ? this.state.places.slice(0, maxResults)
      : this.state.places;

    const noMax = places.length < this.state.totalResults;
    const max = places.length < maxResults;

    const resultsRemaining = this.props.maxResults ? max : noMax;

    const placeAttrsArr = (placeAttrsStr && placeAttrsStr.split(",")) || [];
    const placeAttrsSupplied = Boolean(placeAttrsArr && placeAttrsArr.length);

    // The "show more" button is visible when:
    // - places and maxResults were not supplied
    // - places and limit were supplied, and limit < num places
    // - maxResults and limit were supplied, and limit < maxResults
    //
    // [note] - If maxResults is > PAGE_SIZE the "show more" button will
    // be visible unless the user sets limit and maxResults to the same
    // value. This is because limit defaults to PAGE_SIZE.
    //
    // button visible, limit defaulted to 12
    // <div ... data-max-results="16" />
    //
    // button hidden
    // <div ... data-max-results="16" data-limit="16" />

    const showMoreBtn =
      (!placeAttrsSupplied && !maxResults) ||
      (placeAttrsSupplied && limit < placeAttrsArr.length - 1) ||
      (maxResults && limit < maxResults);

    const place = this.state.placeDetails[this.state.modalPlace];
    const rtConnection = this.props.connections === "roadtrippers";
    return (
      <div className="boone-explorer">
        <div id="boone-modal-anchor" />
        {this.props.showSearch && (
          <SearchForm
            idxCity={this.DEFAULT_CITY_IDX}
            idxCategory={this.DEFAULT_CATEGORY_IDX}
            cities={this.CITIES}
            categories={this.CATEGORIES}
            onSearch={(idxCategory, idxCity) =>
              this.newSearch(idxCategory, idxCity)
            }
          />
        )}
        <div className="be-explorer__content be-explorer__content--theme">
          {this.state.modalOpen && this.props.params.view !== VIEW_TYPE_MAP ? (
            <PlaceDetailsModal
              rtConnection={rtConnection}
              hide={() => this.hideModal()}
              place={place}
              onUserProfile={this.userProfile}
            />
          ) : (
            [
              <React.Fragment key="show-search">
                {this.props.showSearch &&
                places.length > 0 &&
                this.props.params.view !== VIEW_TYPE_LIST &&
                this.props.params.view !== VIEW_TYPE_MAP ? (
                  <div className="be-explorer__headline be-explorer__headline--theme">
                    {`The best ${this.state.category} near ${this.state.city}.`}
                  </div>
                ) : null}
              </React.Fragment>,
              <Results
                key="results"
                data={places.map(place => formatPlaceProps(place))}
                onLoadMore={
                  resultsRemaining ? () => this.loadMore() : undefined
                }
                clientId={this.props.clientId}
                view={this.props.params.view}
                showModal={id => this.showModal(id)}
                viewState={this.viewState}
                showMoreBtn={Boolean(showMoreBtn)}
                rtConnection={rtConnection}
              />,
              this.state.modalOpen && (
                <PlaceDetailsModal
                  rtConnection={rtConnection}
                  popoutModal={true}
                  hide={() => this.hideModal()}
                  place={place}
                  onUserProfile={this.userProfile}
                />
              )
            ]
          )}
        </div>
      </div>
    );
  }
}

Explorer.defaultProps = {
  country: "us"
};
export default Explorer;
