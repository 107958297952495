import React, { Component } from "react";

import Button from "../components/button/index";
import "./styles/base.scss";

const ControlGroup = props => (
  <div className="be-search__control-group be-search__control-group--theme">
    <label
      className="be-search__control-group__label be-search__control-group__label--theme"
      htmlFor={props.for}
    >
      {props.label}
    </label>
    <div className="be-search__control-group-select be-search__control-group-select--theme">
      <label className="be-search__control-group-select-label be-search__control-group-select-label--theme">
        <select
          id={props.id}
          className="be-search__control-group__select-el be-search__control-group__select-el--theme"
          name={props.name}
          value={props.value}
          onChange={props.handleChange}
        >
          {props.children}
        </select>
        <svg
          className="be-search__control-group__select__arrow be-search__control-group__select__arrow--theme"
          width="12px"
          height="12px"
          viewBox="0 0 12 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="chevron-down" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
              d="M12,8.2568235 C12,8.95300234 11.4541469,9.5 10.7594248,9.5 C10.4120638,9.5 10.0895142,9.35081882 9.8662107,9.12704705 L5.99561615,5.07429164 L2.1498331,9.10218352 C1.67841454,9.59945412 0.884446424,9.62431765 0.388216353,9.12704705 C-0.108013717,8.65463998 -0.13282522,7.85900701 0.338593346,7.36173641 L5.10240202,2.38903039 C5.57382059,1.89175979 6.3677887,1.86689626 6.86401877,2.33930333 C6.88883028,2.33930333 6.88883028,2.36416686 6.91364178,2.38903039 L11.6774505,7.36173641 C11.8759425,7.63523524 12,7.9335976 12,8.2568235 Z"
              id="Icon"
              fill="%23006BA6"
              fillRule="nonzero"
              transform="translate(6.000000, 5.750000) scale(1, -1) translate(-6.000000, -5.750000) "
            />
          </g>
        </svg>
      </label>
    </div>
  </div>
);

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = { idxCity: this.props.idxCity, idxCategory: this.props.idxCategory };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
  }

  componentDidMount() {
    this.handleSearchClick();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSearchClick() {
    this.props.onSearch(this.state.idxCategory, this.state.idxCity);
  }

  render() {
    return (
      <div data-cy="boone-search-form" className="be-search be-search--theme">
        <div className="be-search__search-form be-search__search-form--theme">
          <ControlGroup
            value={this.state.idxCategory}
            label="Show"
            for="tags"
            name="idxCategory"
            id='category-select'
            handleChange={e => this.handleChange(e)}
          >
            {this.props.categories.map((tag, idx) => (
              <option key={'category-' + idx} value={idx}>{tag[0]}</option>
            ))}
          </ControlGroup>

          <ControlGroup
            value={this.state.idxCity}
            label="near"
            for="center"
            name="idxCity"
            id='city-select'
            handleChange={e => this.handleChange(e)}
          >
            {this.props.cities.map((city, idx) => (
              <option key={ "city-" + idx} value={idx}>
                {city[0]}
              </option>
            ))}
          </ControlGroup>
          <Button
            id='search-btn'
            className="be-search__search-button be-search__search-button--theme"
            onClick={this.handleSearchClick}
          >
            Search
          </Button>
        </div>
      </div>
    );
  }
}

export default SearchForm;
