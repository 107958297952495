export default {
  details: {
    private_property: {
      label: "Private Property",
      dataType: "bool",
      valuePrefix: { true: "", false: "Not " }
    },
    chain: {
      label: "Chain",
      dataType: "bool",
      valuePrefix: { true: "", false: "Not a " }
    },
    credit_cards: { label: "Credit Cards Accepted", dataType: "bool" },
    pets: { label: "Pets Allowed", dataType: "bool" },
    accessible: {
      label: "Wheelchair Accessible",
      dataType: "bool",
      valuePrefix: { true: "", false: "Not " }
    },
    wifi: { label: "Wifi Available", dataType: "bool" },
    restrooms: { label: "Restrooms Available", dataType: "bool" },
    // Let's skip this one. permanently_closed: {label: 'Permanently Closed', dataType: 'bool', valuePrefix: {true: '', false: 'Not '}},
    haunted: {
      label: "Haunted",
      dataType: "bool",
      valuePrefix: { true: "", false: "Not " }
    }
  },
  parking_details: {
    lot: { label: "Parking Lot Available", dataType: "bool" },
    street: { label: "Street Parking Available", dataType: "bool" },
    valet: { label: "Valet Parking Available", dataType: "bool" },
    private: { label: "Private Parking Available", dataType: "bool" },
    garage: { label: "Garage Parking Available", dataType: "bool" },
    rv: { label: "RV Parking Available", dataType: "bool" },
    free: { label: "Free Parking Available", dataType: "bool" },
    clearance: { label: "Parking clearance", dataType: "string" },
    price: { label: "Parking fee", dataType: "string" },
    available: { label: "Parking Available", dataType: "bool" }
  },
  hotel_details: {
    rooms_count: { label: "Number of rooms", dataType: "string" },
    room_services: {
      label: "Available room services",
      dataType: "array",
      allowedValues: [
        { label: "No room service", value: "none" },
        { label: "Limited room service", value: "limited" },
        { label: "24 hour room service", value: "24 hrs" }
      ]
    },
    check_in: { label: "Check-in time", dataType: "string" },
    check_out: { label: "Check-out time", dataType: "string" },
    all_day_front_desk: { label: "24-hour Front Desk", dataType: "bool" },
    adult_only: { label: "Adults only", dataType: "bool" },
    air_conditioning: { label: "Air Conditioning", dataType: "bool" },
    bar: { label: "Bar", dataType: "bool" },
    beachfront: { label: "Beachfront", dataType: "bool" },
    business_center: { label: "Business Center", dataType: "bool" },
    car_rental: { label: "Car Rental", dataType: "bool" },
    childcare: { label: "Child care", dataType: "bool" },
    childrens_area: { label: "Children's Area", dataType: "bool" },
    continental_breakfast: { label: "Continental Breakfast", dataType: "bool" },
    currency_exchange: { label: "Currency Exchange", dataType: "bool" },
    fitness_facility: { label: "Fitness Facility", dataType: "bool" },
    laundry: { label: "Laundry Service", dataType: "bool" },
    lockers: { label: "Lockers", dataType: "bool" },
    luggage_storage: { label: "Luggage Storage", dataType: "bool" },
    meeting_facilities: { label: "Meeting Facilities", dataType: "bool" },
    nightclub: { label: "Nightclub", dataType: "bool" },
    onsite_entertainment: { label: "On-site Entertainment", dataType: "bool" },
    outdoor_activities: { label: "Outdoor Activities", dataType: "bool" },
    prayer_room: { label: "Prayer Room", dataType: "bool" },
    restaurant: { label: "Restaurant", dataType: "bool" },
    safe_box: { label: "Safe Box", dataType: "bool" },
    shared_kitchen: { label: "Shared Kitchen", dataType: "bool" },
    shuttle_service: {
      label: "Shuttle Services",
      dataType: "array",
      allowedValues: [
        { label: "Shuttle services available", value: "yes" },
        { label: "Paid shuttle services", value: "paid" },
        { label: "Free shuttle services", value: "free" }
      ]
    },
    spa_service: { label: "Spa Service", dataType: "bool" },
    tour_desk: { label: "Tour Desk", dataType: "bool" }
  },
  restaurant_details: {
    breakfast: { label: "Breakfast", dataType: "bool" },
    coffee: { label: "Coffee", dataType: "bool" },
    dinner: { label: "Dinner", dataType: "bool" },
    drinks: { label: "Drinks", dataType: "bool" },
    lunch: { label: "Lunch", dataType: "bool" },
    reservations: {
      label: "Reservations",
      dataType: "string-select",
      allowedValues: [
        "required",
        "takes",
        "does not take",
        "strongly suggested"
      ]
    },
    private_dining: { label: "Private Dining", dataType: "bool" },
    bar: { label: "Bar", dataType: "bool" },
    outdoor_seating: { label: "Outdoor Seating", dataType: "bool" },
    waitstaff: { label: "Wait staff", dataType: "bool" },
    takeout: { label: "Takeout", dataType: "bool" },
    brunch: { label: "Brunch", dataType: "bool" }
  },
  campground_details: {
    sites_count: { label: "Sites Count", dataType: "integer" },
    pull_through: { label: "Pull Through", dataType: "bool" },
    full_hookup: { label: "Full Hookup", dataType: "bool" },
    fifty_amp: { label: "Fifty Amp", dataType: "bool" },
    big_rigs: { label: "Big Rigs", dataType: "bool" },
    tent_sites: { label: "Tent Sites", dataType: "bool" },
    dump_station: { label: "Dump Station", dataType: "bool" },
    sewer_hookup: { label: "Sewer Hookup", dataType: "bool" },
    showers: { label: "Showers", dataType: "bool" },
    laundry: { label: "Laundry", dataType: "bool" },
    paved_sites: { label: "Paved Sites", dataType: "bool" },
    swimming_pool: { label: "Swimming Pool", dataType: "bool" },
    rec_facilities: { label: "Recreational Facilities", dataType: "bool" },
    firewood: { label: "Firewood", dataType: "bool" },
    propane: { label: "Propane", dataType: "bool" },
    max_length: { label: "Max Length", dataType: "string" },
    season_start: { label: "Season Start", dataType: "string" },
    season_end: { label: "Season End", dataType: "string" },
    check_in: { label: "Check-in", dataType: "string" },
    check_out: { label: "Check-out", dataType: "string" },
    quiet_hours_start_at: { label: "Quiet Hours Start At", dataType: "string" },
    quiet_hours_end_at: { label: "Quiet Hours End At", dataType: "string" }
  }
};
