import React, { PureComponent } from "react";
import RoadtrippersLogo from "../roadtrippersLogo";
import Button from "../components/button";
import { VIEW_TYPE_LIST, RT_MAPS_URL, VIEW_TYPE_MAP } from "../constants/base";
import MapLayout from "./map-layout";
import GridLayout from "./grid-layout";
import EditorialLayout from "./editorial-layout";
import "./styles/base.scss";

class Results extends PureComponent {
  async componentDidMount() {
    if (this.props.viewState && this.props.viewState.yOffset !== undefined) {
      window.scrollTo({ top: this.props.viewState.yOffset });
      delete this.props.viewState.yOffset;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => this.handleScroll());
  }

  handleScroll() {
    this.props.viewState.yOffset = window.scrollY;
  }

  showModal(e, id) {
    e.stopPropagation();
    this.props.showModal(id);
  }

  render() {
    const loadMoreDisabled = this.props.onLoadMore ? "" : " be-disabled";
    return (
      <div className="be-results be-results--theme">
        {this.props.view === VIEW_TYPE_MAP && (
          <MapLayout
            data={this.props.data}
            showModal={(e, id) => this.showModal(e, id)}
          />
        )}

        {this.props.view === VIEW_TYPE_LIST && (
          <EditorialLayout
            data={this.props.data}
            showModal={(e, id) => this.showModal(e, id)}
          />
        )}

        {this.props.view !== VIEW_TYPE_LIST &&
          this.props.view !== VIEW_TYPE_MAP && (
            <GridLayout
              data={this.props.data}
              showModal={(e, id) => this.showModal(e, id)}
            />
          )}

        <div
          data-cy="boone-results-actions-buttons"
          className="be-results-actions be-results-actions--theme"
        >
          {this.props.showMoreBtn && [
            <Button
              id="load-more-btn"
              key="load-more-btn-key"
              data-cy="load-more-btn"
              disabledClassName={loadMoreDisabled}
              className="be-results-action-button be-results__action-button__see-more--theme be-results__action-button__see-more"
              onClick={this.props.onLoadMore}
            >
              See more cool places
            </Button>,
            this.props.rtConnection ? (
              <span
                key="seperator"
                data-cy="results-actions-button-separator"
                className={
                  "be-results__action-buttons__separator be-results__action-buttons__separator--theme" +
                  loadMoreDisabled
                }
              >
                or
              </span>
            ) : null
          ]}
          {this.props.rtConnection ? (
            <Button
              key="visit-rt-button"
              data-cy="rt-connection-plan-trip-button"
              link
              url={RT_MAPS_URL}
              className="be-results-action-button be-results__action-button__visit-rt be-results__action-button__visit-rt--theme"
            >
              <>
                <RoadtrippersLogo fillClass="be-results__action-button__visit-rt__icon--theme" />
                {`Plan your next trip`}
              </>
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Results;
