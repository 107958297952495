import React from "react";
import Loadable from "react-loadable";

const LoadableComponent = Loadable({
  loader: () => import(/*webpackChunkName: "grid-layout.chunk"*/ "./component"),
  loading: () => <div>...</div>
});

export default class GridLayout extends React.Component {
  render() {
    return <LoadableComponent {...this.props} />;
  }
}
