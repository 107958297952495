import React, { Component } from "react";
import ReactDOM from "react-dom";
import ATTRIBUTES from "../constants/attributes.js";
import * as rt from "./components/rt-connection";
import "./styles/base.scss";
import Gallery from "./components/gallery";
import * as Modal from "./components/modal";
import TruncateText from "../components/truncate-text";

const DAY_FIELDS = {
  sun_open_close: "SUN",
  mon_open_close: "MON",
  tue_open_close: "TUE",
  wed_open_close: "WED",
  thu_open_close: "THU",
  fri_open_close: "FRI",
  sat_open_close: "SAT"
};

const formatTime = function(timeStr) {
  var [hour, min] = timeStr.split(":");
  var hourAsI = parseInt(hour);
  var pm = hourAsI >= 12;
  if (hourAsI === 0) {
    hourAsI = 12;
  } else if (hourAsI > 12) {
    hourAsI -= 12;
  }
  return hourAsI + ":" + min + (pm ? "PM" : "AM");
};

class PlaceDetailsModal extends Component {
  state = {};

  modalRef = React.createRef();

  modalBodyRef = React.createRef();

  componentWillMount() {
    if (this.props.place && this.props.place.reviews) {
      for (let i = 0; i < this.props.place.reviews.slice(0, 5).length; ++i) {
        let review = this.props.place.reviews[i];
        if (review.source_user) {
          this.userAvatarUrl(review.source_user);
        }
      }
    }
  }

  hideModal(e) {
    if (this.modalBodyRef && this.modalBodyRef.current) {
      if (
        this.modalBodyRef.current !== e.target &&
        !this.modalBodyRef.current.contains(e.target) &&
        // Nested, conditionally rendered elements are not considered
        // children of the ModalContent div. This check ensures
        // that the Modal does not close if links are clicked.
        e.target.tagName !== "A"
      ) {
        this.props.hide();
      }
    }
  }

  componentDidMount() {
    if (!this.props.popoutModal && this.modalRef && this.modalRef.current) {
      this.modalRef.current.scrollIntoView();
    }
    document.addEventListener("click", e => this.hideModal(e));
  }

  componentWillUnmount() {
    document.removeEventListener("click", e => this.hideModal(e));
  }

  renderModalContents() {
    return (
      <Modal.Container ref={this.modalRef}>
        <Modal.Header hide={this.props.hide} />
        <Modal.Body ref={this.modalBodyRef}>
          <Gallery
            images={this.props.place.images}
            linkToRT={this.props.rtConnection}
            urlRT={this.rtUrl()}
          />

          <div className="be-place-details__info">
            <div className="be-place-details__title be-place-details__title--theme">
              {this.props.place.name}
            </div>
            <div className="be-place-details__subtitle-row">
              {this.props.place.engagement ? (
                <div className="be-place-details__rating-badge be-place-details__rating-badge--theme">
                  <span className="be-place-details__rating be-place-details__rating--theme">
                    {this.props.place.engagement.avg_rating}
                  </span>
                  <span className="be-place-details__rating-badge-denominator be-place-details__rating-badge-denominator--theme">
                    / 5
                  </span>
                </div>
              ) : null}

              <div className="be-place-details__subtitle be-place-details__subtitle--theme">
                {this.props.place.subtitle}
              </div>
            </div>

            <div
              data-cy="boone-place-details-modal-content-description"
              className="be-place-details__description"
            >
              <TruncateText text={this.props.place.description} />
            </div>

            <div data-cy="boone-modal-buttons" className="be-modal-buttons">
              {this.props.rtConnection ? (
                <>
                  <rt.SaveButton rtUrl={this.rtUrl()} />
                  <rt.CheckItOutButton rtUrl={this.rtUrl()} />
                </>
              ) : null}
            </div>

            <div className="be-row be-place-details__section-divider be-place-details__section-divider--theme">
              <div className="be-col-one-third be-place-details__section-divider be-place-details__section-divider--theme">
                <div className="be-place-details__section-header be-place-details__section-header--theme">
                  Address
                </div>
                {this.props.place.locations && this.props.place.locations[0] ? (
                  <div
                    data-cy="boone-place-details-modal-content-address"
                    className="be-address"
                  >
                    <div className="be-place-details__field be-place-details__field--theme">
                      {this.props.place.locations[0].address1}
                    </div>
                    {this.props.place.locations[0].address2 ? (
                      <div className="be-place-details__field be-place-details__field--theme">
                        {this.props.place.locations[0].address2}
                      </div>
                    ) : null}
                    <div className="be-place-details__field be-place-details__field--theme">
                      {`${this.props.place.locations[0].city}${", " +
                        this.props.place.locations[0].state} ${
                        this.props.place.locations[0].postal_code
                      }`}
                    </div>
                    <div className="be-place-details__field be-place-details__field--theme">
                      {this.props.place.locations[0].country}
                    </div>
                    <div className="be-place-details__field be-place-details__field--theme" />
                    <div className="be-geolocation-wrap">
                      <div className="be-place-details__section-header be-place-details__section-header--theme">
                        Geolocation
                      </div>
                      <div className="be-place-details__field be-place-details__field--theme">
                        {`${this.props.place.locations[0].latitude}${
                          this.props.place.locations[0].latitude &&
                          this.props.place.locations[0].longitude
                            ? ", "
                            : ""
                        }
                          ${this.props.place.locations[0].longitude}`}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="be-col-one-third be-place-details__section-divider be-place-details__section-divider--theme">
                <div className="be-place-details__section-header be-place-details__section-header--theme">
                  Regions
                </div>
                {this.props.place.regions_hierarchy
                  ? this.props.place.regions_hierarchy
                      .slice(0)
                      .reverse()
                      .map((region, index) => (
                        <div
                          className="be-place-details__field be-place-details__field--theme"
                          key={index + "-regions_hierarchy"}
                        >
                          {region.name}
                        </div>
                      ))
                  : null}
              </div>
              <div className="be-col-one-third be-place-details__section-divider be-place-details__section-divider--theme">
                <div className="be-place-details__section-header be-place-details__section-header--theme">
                  Hours
                </div>
                {this.props.place.hours && this.props.place.hours["<default>"]
                  ? Object.keys(this.props.place.hours["<default>"]).map(
                      dayKey => (
                        <div
                          key={dayKey}
                          className="be-place-details__field be-place-details__field--theme"
                        >
                          {`${DAY_FIELDS[dayKey]} ${this.formatHours(
                            this.props.place.hours["<default>"][dayKey]
                          )}`}
                        </div>
                      )
                    )
                  : null}
              </div>
            </div>
            <div className="be-row be-place-details__section-divider be-place-details__section-divider--theme">
              <div className="be-col-one-third be-place-details__section-divider be-place-details__section-divider--theme">
                <div>
                  <div className="be-place-details__section-header be-place-details__section-header--theme">
                    Tags
                  </div>
                  {this.props.place.tags &&
                    this.props.place.tags.map(tag => (
                      <div
                        className="be-place-details__field be-place-details__field--theme"
                        key={tag.public_id}
                      >
                        {tag.name}
                      </div>
                    ))}
                </div>
                <div>
                  <div className="be-place-details__section-header be-place-details__section-header--theme mt-5">
                    Activities
                  </div>
                  {this.props.place.activities &&
                    this.props.place.activities.map(activity => (
                      <div
                        className="be-place-details__field be-place-details__field--theme"
                        key={activity.public_id}
                      >
                        {activity.name}
                      </div>
                    ))}
                </div>
              </div>
              <div className="be-col-one-third be-place-details__section-divider be-place-details__section-divider--theme">
                <div className="be-place-details__section-header be-place-details__section-header--theme">
                  Attributes
                </div>
                {this.renderAttributes()}
              </div>
              <div className="be-col-one-third be-place-details__section-divider be-place-details__section-divider--theme">
                <div className="be-place-details__section-header be-place-details__section-header--theme">
                  Contact
                </div>
                {this.props.place.contact_info ? (
                  <div>
                    <div className="be-place-details__field be-place-details__field--theme">
                      {this.props.place.contact_info.phone}
                    </div>
                    <div className="be-place-details__field be-place-details__field--theme">
                      {this.props.place.contact_info.email}
                    </div>
                    {this.props.place.contact_info.website ? (
                      <div className="be-place-details__field be-place-details__field--theme">
                        <a
                          className="be-place-details__field-link be-place-details__field-link--theme"
                          href={this.props.place.contact_info.website}
                          target="_blank"
                        >
                          {this.props.place.contact_info.website}
                        </a>
                      </div>
                    ) : null}
                    {this.props.place.social_handles &&
                    this.props.place.social_handles[0] ? (
                      <div className="be-place-details__field be-place-details__field--theme">
                        <a
                          href={this.props.place.social_handles[0].url}
                          target="_blank"
                        >
                          {this.props.place.social_handles[0].network}
                        </a>
                      </div>
                    ) : null}
                    <div className="be-place-details__field be-place-details__field--theme" />
                  </div>
                ) : null}
              </div>
            </div>
            {this.props.place.reviews ? (
              <div className="be-row be-place-details__section-divider be-place-details__section-divider--theme">
                {this.renderReviews()}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal.Container>
    );
  }

  render() {
    return this.props.place
      ? this.props.popoutModal
        ? ReactDOM.createPortal(
            this.renderModalContents(),
            document.getElementById("boone-modal-anchor")
          )
        : this.renderModalContents()
      : null;
  }

  renderAttributes() {
    let details = [
      "details",
      "hotel_details",
      "campground_details",
      "restaurant_details",
      "parking_details"
    ];
    return details.map(d => this.renderAttributeDetails(d));
  }

  renderAttributeDetails(detailsKey) {
    const details = this.props.place[detailsKey];
    return Object.keys(details).map(attributeName =>
      this.renderAttribute(detailsKey, attributeName, details[attributeName])
    );
  }

  renderAttribute(detailsKey, attribute, value) {
    let attributeDescription = null;
    const attr = ATTRIBUTES[detailsKey][attribute];
    if (attr) {
      let label = attr.label;
      if (attr.dataType === "bool") {
        const valuePrefix = attr.valuePrefix || { true: "", false: "No " };
        attributeDescription = valuePrefix[value] + label;
      } else if (attr.dataType === "string") {
        attributeDescription = label + ": " + value;
      }
    }
    if (attributeDescription) {
      return (
        <div
          className="be-place-details__field be-place-details__field--theme"
          key={attribute}
        >
          {attributeDescription}
        </div>
      );
    } else {
      return null;
    }
  }

  renderReviews() {
    const source = review =>
      this.props.rtConnection ? (
        <a
          className="be-place-details__reviewer-link be-place-details__reviewer-link--theme"
          href={review.url}
          target="_blank"
        >
          {review.source_user}
        </a>
      ) : (
        <div className="be-place-details__reviewer be-place-details__reviewer--theme">
          {review.source_user}
        </div>
      );

    return this.props.place.reviews.slice(0, 5).map((review, index) => (
      <div className="be-review" key={index + "-review"}>
        <div className="be-user-avatar">
          {this.state.userAvatars &&
          this.state.userAvatars[review.source_user] ? (
            <img src={this.state.userAvatars[review.source_user]} />
          ) : (
            <div className="be-avatar-ph" />
          )}
        </div>
        <div className="be-user-review clearfix">
          {source(review)}
          <TruncateText text={review.text} numChars={300} />
        </div>
      </div>
    ));
  }

  renderRtLink(childComponent) {
    const url = this.rtUrl();
    return url ? (
      <a
        className="be-place-details__field-link be-place-details__field-link--theme"
        href={url}
        target="_blank"
      >
        {childComponent}
      </a>
    ) : (
      childComponent
    );
  }

  showFullDescription(event, value) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showFullDescription: value });
  }

  showFullReview(event, value, index) {
    event.preventDefault();

    let reviews = this.state.showFullReviews || {};
    reviews[index] = value;
    this.setState({ showFullReviews: reviews });
  }

  formatHours(hours) {
    let retVal = "";
    if (hours.length === 0) {
      retVal = "Closed";
    } else {
      let hoursStr = hours[0];
      if (hoursStr === "24hrs") {
        retVal = "All Day";
      } else {
        [open, close] = hoursStr.split("-");
        if (open === close) {
          retVal = "All Day";
        } else {
          retVal = formatTime(open) + (close ? " - " + formatTime(close) : "");
        }
      }
    }
    return retVal;
  }

  userAvatarUrl(userName) {
    this.props.onUserProfile(userName).then(resultsJson => {
      let userAvatars = this.state.userAvatars || {};
      if (resultsJson.image && resultsJson.image.v_50x50_url) {
        userAvatars[userName] = resultsJson.image.v_50x50_url;
      }
      this.setState({ userAvatars: userAvatars });
    });
  }

  rtUrl() {
    if (this.props.place.attribution) {
      let rtAttribution = this.props.place.attribution.find(
        attribution => attribution.source_id === 1
      );
      if (rtAttribution) {
        return rtAttribution.source_item_url;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

export default PlaceDetailsModal;
