export default [
  ["Toronto", [-79.42, 43.7], 100],
  ["Montréal", [-73.5833, 45.5], 100],
  ["Vancouver", [-123.1216, 49.2734], 100],
  ["Ottawa", [-75.7, 45.4167], 100],
  ["Calgary", [-114.08, 51.083], 100],
  ["Edmonton", [-113.5, 53.55], 100],
  ["Hamilton", [-79.83, 43.25], 100],
  ["Winnipeg", [-97.166, 49.883], 100],
  ["Québec", [-71.2456, 46.84], 100],
  ["Oshawa", [-78.85, 43.88], 100],
  ["Kitchener", [-80.5, 43.45], 100],
  ["Halifax", [-63.6, 44.65], 100],
  ["London", [-81.25, 42.97], 100],
  ["Windsor", [-83.0333, 42.3333], 100],
  ["Victoria", [-123.35, 48.4333], 100],
  ["Saskatoon", [-106.67, 52.17], 100],
  ["Barrie", [-79.7, 44.3838], 100],
  ["Regina", [-104.617, 50.45], 100],
  ["Sudbury", [-80.9666, 46.5], 100],
  ["Abbotsford", [-122.3, 49.0504], 100],
  ["Sarnia", [-82.4, 42.9666], 100],
  ["Sherbrooke", [-71.9, 45.4], 100],
  ["St. John's", [-52.681, 47.585], 100],
  ["Kelowna", [-119.4833, 49.9], 100],
  ["Trois-Rivières", [-72.5499, 46.35], 100],
  ["Kingston", [-76.4833, 44.2337], 100],
  ["Thunder Bay", [-89.275, 48.4462], 100],
  ["Moncton", [-64.7667, 46.0833], 100],
  ["Saint John", [-66.0767, 45.267], 100],
  ["Nanaimo", [-123.9343, 49.146], 100],
  ["Peterborough", [-78.3333, 44.3], 100],
  ["St.-Jerome", [-74, 45.7666], 100],
  ["Red Deer", [-113.8, 52.2666], 100],
  ["Lethbridge", [-112.8333, 49.7005], 100],
  ["Kamloops", [-120.3333, 50.6667], 100],
  ["Prince George", [-122.7667, 53.9167], 100],
  ["Medicine Hat", [-110.6833, 50.0333], 100],
  ["Drummondville", [-72.4834, 45.8833], 100],
  ["Chicoutimi", [-71.0667, 48.4333], 100],
  ["Fredericton", [-66.6333, 45.95], 100],
  ["Chilliwack", [-121.95, 49.1666], 100],
  ["North Bay", [-79.45, 46.3], 100],
  ["Shawinigan", [-72.7333, 46.5504], 100],
  ["Cornwall", [-74.7333, 45.0171], 100],
  ["Joliette", [-73.4333, 46.0333], 100],
  ["Belleville", [-77.3833, 44.1667], 100],
  ["Charlottetown", [-63.1313, 46.2493], 100],
  ["Victoriaville", [-71.9667, 46.0504], 100],
  ["Grand Prairie", [-118.8, 55.1666], 100],
  ["Penticton", [-119.5833, 49.5004], 100],
  ["Sydney", [-60.18, 46.0661], 100],
  ["Orillia", [-79.4167, 44.6], 100],
  ["Rimouski", [-68.5167, 48.4337], 100],
  ["Timmins", [-81.3333, 48.4666], 100],
  ["Prince Albert", [-105.75, 53.2], 100],
  ["Campbell River", [-125.25, 50.0171], 100],
  ["Courtenay", [-125, 49.6833], 100],
  ["Orangeville", [-80.0833, 43.9171], 100],
  ["Moose Jaw", [-105.55, 50.4], 100],
  ["Brandon", [-99.95, 49.8333], 100],
  ["Brockville", [-75.6953, 44.5893], 100],
  ["Saint-Georges", [-70.6667, 46.1171], 100],
  ["Sept-Îles", [-66.36, 50.3161], 100],
  ["Rouyn-Noranda", [-79.0332, 48.25], 100],
  ["Whitehorse", [-135.05, 60.7167], 100],
  ["Owen Sound", [-80.85, 44.5666], 100],
  ["Fort McMurray", [-111.3833, 56.7333], 100],
  ["Corner Brook", [-57.9333, 48.95], 100],
  ["Val d'Or", [-77.7666, 48.1166], 100],
  ["New Glasgow", [-62.6333, 45.5833], 100],
  ["Terrace", [-128.5833, 54.5], 100],
  ["North Battleford", [-108.2833, 52.7666], 100],
  ["Yellowknife", [-114.397, 62.442], 100],
  ["Fort St. John", [-120.8333, 56.25], 100],
  ["Cranbrook", [-115.7667, 49.5167], 100],
  ["Edmundston", [-68.3333, 47.3794], 100],
  ["Rivière-du-Loup", [-69.5333, 47.8333], 100],
  ["Camrose", [-112.8166, 53.0167], 100],
  ["Pembroke", [-77.1166, 45.8503], 100],
  ["Yorkton", [-102.4665, 51.2171], 100],
  ["Swift Current", [-107.7666, 50.2837], 100],
  ["Prince Rupert", [-130.33, 54.3167], 100],
  ["Williams Lake", [-122.15, 52.1166], 100],
  ["Brooks", [-111.9, 50.5671], 100],
  ["Quesnel", [-122.4833, 52.9837], 100],
  ["Thompson", [-97.8666, 55.7499], 100],
  ["Dolbeau", [-72.2333, 48.8666], 100],
  ["Powell River", [-124.55, 49.8837], 100],
  ["Wetaskiwin", [-113.3833, 52.9666], 100],
  ["Nelson", [-117.2833, 49.4837], 100],
  ["Mont-Laurier", [-75.5, 46.5504], 100],
  ["Kenora", [-94.4666, 49.7667], 100],
  ["Dawson Creek", [-120.2333, 55.767], 100],
  ["Amos", [-78.1167, 48.5666], 100],
  ["Baie-Comeau", [-68.158, 49.2227], 100],
  ["Hinton", [-117.5834, 53.4], 100],
  ["Selkirk", [-96.8833, 50.15], 100],
  ["Steinbach", [-96.6833, 49.5171], 100],
  ["Weyburn", [-103.85, 49.6666], 100],
  ["Amherst", [-64.2166, 45.8166], 100]
].sort((a, b) => a[0].localeCompare(b[0]));
