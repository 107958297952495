import React from "react";
import Loadable from "react-loadable";

const LoadableComponent = Loadable({
  loader: () => import(/*webpackChunkName: "editorial-layout.chunk"*/ "./component"),
  loading: () => <div>...</div>
});

export default class EditorialLayout extends React.Component {
  render() {
    return <LoadableComponent {...this.props} />;
  }
}
