import React from "react";
import Image from "../index";

const CloseSVG = ({ fillClass }) => (
  <svg width="28" height="28">
    <path
      className={fillClass}
      d="M15.00025 7.00025h-6v-6h2v4h4v2zm-8 8h-2v-4h-4v-2h6v6zm3-8c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l5-5c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-5 5c-.195.195-.451.293-.707.293zm-9 9c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l5-5c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-5 5c-.195.195-.451.293-.707.293z"
    />
  </svg>
);

export const Header = ({ hide }) => (
  <>
    <span
      data-cy="boone-place-details-modal-close-button-mobile"
      className="be-close-button be-show-mobile"
      onClick={() => hide()}
    >
      <CloseSVG />
    </span>
    <span
      data-cy="boone-place-details-modal-close-button"
      className="be-close-button be-hide-mobile"
      onClick={() => hide()}
    >
      <CloseSVG />
    </span>
  </>
);

export const Container = React.forwardRef((props, ref) => (
  <div
    data-cy="boone-place-details-modal"
    className="be-place-details__modal__outer-container"
    ref={ref}
  >
    <div className="be-place-details__modal be-place-details__modal--theme">
      {props.children}
    </div>
  </div>
));

export const Body = React.forwardRef((props, ref) => (
  <div
    data-cy="boone-place-details-modal-content"
    className="be-place-details__modal-content"
    ref={ref}
  >
    {props.children}
  </div>
));
