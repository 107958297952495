export const aOrB = (condition, a, b) => (condition ? a : b);

// ${Attributes color and size are} required.
// ${Attributes color, size, and date are} required.
// The ${attribute color is} required.
export const fmtAdjNounsVerb = (
  arr,
  adjSingular,
  adjPlural = adjSingular + "s",
  conjunction = "and"
) => {
  const plural = arr.length > 1;
  const moreThanTwo = arr.length > 2;
  const join = arr.length === 2 ? ` ${conjunction} ` : ", ";
  const verb = aOrB(plural, "are", "is");
  const adj = aOrB(plural, adjPlural, adjSingular);
  if (moreThanTwo) {
    arr[arr.length - 1] = `${conjunction} ${arr[arr.length - 1]}`;
  }
  const nouns = `${arr}`.split(",").join(join);
  const oxfordComa = moreThanTwo ? "," : "";
  return `${adj} ${nouns}${oxfordComa} ${verb}`;
};

export const camelToHyphen = str =>
  str.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);

export const formatURLParams = params =>
  Object.keys(params)
    .filter(key => params[key])
    .reduce((prev, key) => prev + "&" + key + "=" + params[key], "");
