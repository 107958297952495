export default [
  ["Auckland", [174.763, -36.8481], 100],
  ["Wellington", [174.7833, -41.3], 100],
  ["Manukau", [174.885, -37], 100],
  ["Christchurch", [172.63, -43.535], 100],
  ["Waitakere", [174.5543, -36.8524], 100],
  ["North Shore", [174.7758, -36.7913], 100],
  ["Hamilton", [175.2896, -37.7783], 100],
  ["Tauranga", [176.1536, -37.6964], 100],
  ["Dunedin", [170.491, -45.8854], 100],
  ["Lower Hutt", [174.9123, -41.2037], 100],
  ["Palmerston North", [175.6072, -40.3527], 100],
  ["Nelson", [173.2474, -41.2926], 100],
  ["Napier", [176.9265, -39.49], 100],
  ["Rotorua", [176.2483, -38.1317], 100],
  ["New Plymouth", [174.0748, -39.0556], 100],
  ["Porirua", [174.8524, -41.1219], 100],
  ["Whangarei", [174.323, -35.7256], 100],
  ["Invercargill", [168.365, -46.4094], 100],
  ["Wanganui", [175.0393, -39.9326], 100],
  ["Upper Hutt", [175.029, -41.1355], 100],
  ["Gisborne", [178.0181, -38.6633], 100],
  ["Timaru", [171.2425, -44.3972], 100],
  ["Pukekohe", [174.9033, -37.2015], 100],
  ["Paraparaumu", [174.9989, -40.9034], 100],
  ["Taupo", [176.0771, -38.693], 100],
  ["Masterton", [175.6456, -40.9439], 100],
  ["Levin", [175.2772, -40.6124], 100],
  ["Whakatane", [176.9828, -37.9574], 100],
  ["Ashburton", [171.7486, -43.8983], 100],
  ["Queenstown", [168.7251, -45.0227], 100],
  ["Cambridge", [175.4703, -37.8894], 100],
  ["Tokoroa", [175.8705, -38.2245], 100],
  ["Hawera", [174.278, -39.5795], 100],
  ["Kaiapoi", [172.651, -43.3813], 100],
  ["Greymouth", [171.2231, -42.4716], 100],
  ["Gore", [168.9604, -46.0947], 100],
  ["Thames", [175.5853, -37.1384], 100],
  ["Kerikeri", [173.9507, -35.2285], 100],
  ["Kaitaia", [173.2727, -35.1099], 100],
  ["Wanaka", [169.1449, -44.698], 100],
  ["Westport", [171.6028, -41.7638], 100],
  ["Turangi", [175.8142, -38.9889], 100],
  ["Hokitika", [171.01, -42.7072], 100],
  ["Picton", [174.0166, -41.2805], 100],
  ["Kaikoura", [173.6897, -42.4012], 100],
  ["Te Anau", [167.7585, -45.4074], 100],
  ["Oban", [168.1666, -46.8876], 100],
  ["Waitangi", [-176.3696, -44.0263], 100]
].sort((a, b) => a[0].localeCompare(b[0]));
