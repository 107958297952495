import React, { PureComponent } from "react";

class Image extends PureComponent {
  render() {
    const { className, url, showPlaceholder } = this.props;
    if (url) {
      return <img className={className} src={url} />;
    } else {
      return showPlaceholder ? <div className="be-image__placeholder" /> : null;
    }
  }
}

export default Image;
