import React from "react";
import Link from "../link";

const TruncateText = ({ text, cy, numChars = 500 }) => {
  const [showFullText, setShowFullText] = React.useState(false);
  const [formattedText, setFormattedText] = React.useState("");

  React.useEffect(() => {
    if (text) {
      let t = text.replace(/<[^>]*>?/gm, "").replace(/\n\s*\n/g, "\n\n");
      setFormattedText(t);
    }
  }, []);

  return (
    <div data-cy={cy} className="be-place-details__description">
      {formattedText ? (
        <div className="be-white-space">
          {formattedText.length < numChars ? (
            formattedText
          ) : showFullText ? (
            <>
              {formattedText}
              <Link
                className="be-place-details__read-more be-place-details__read-more--theme"
                target="_blank"
                data-cy="boone-read-less"
                onClick={() => setShowFullText(false)}
              >
                read less
              </Link>
            </>
          ) : (
            <>
              {formattedText.substring(0, numChars)}...
              <Link
                className="be-place-details__read-more be-place-details__read-more--theme"
                target="_blank"
                data-cy="boone-read-more"
                onClick={() => setShowFullText(true)}
              >
                read more
              </Link>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TruncateText;
