import React from "react";
import cn from "classnames";

const Button = ({
  url,
  onClick,
  disabledClassName,
  className,
  link,
  id,
  children
}) =>
  link ? (
    <a
      id={id}
      href={url}
      className={cn({
        [className]: className,
        [disabledClassName]: [disabledClassName]
      })}
      target="_blank"
    >
      {children}
    </a>
  ) : (
    <button
      id={id}
      onClick={onClick}
      className={cn({
        [className]: className,
        [disabledClassName]: [disabledClassName]
      })}
    >
      {children}
    </button>
  );

export default Button;
