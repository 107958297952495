import React from "react";
import Loadable from "react-loadable";

const LoadableComponent = Loadable({
  loader: () => import(/*webpackChunkName: "map-layout.chunk"*/ "./component"),
  loading: () => <div>...</div>
});

export default class MapLayout extends React.Component {
  render() {
    return <LoadableComponent {...this.props} />;
  }
}
