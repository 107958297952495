export default [
  ["New York, NY", [-74.00597, 40.71427], 100],
  ["Los Angeles, CA", [-118.24368, 34.05223], 100],
  ["Chicago, IL", [-87.65005, 41.85003], 100],
  ["Houston, TX", [-95.36327, 29.76328], 100],
  ["Phoenix, AZ", [-112.07404, 33.44838], 100],
  ["Philadelphia, PA", [-75.16379, 39.95233], 100],
  ["San Antonio, TX", [-98.49363, 29.42412], 100],
  ["San Diego, CA", [-117.15726, 32.71533], 100],
  ["Dallas, TX", [-96.80667, 32.78306], 100],
  ["San Jose, CA", [-121.89496, 37.33939], 100],
  ["Austin, TX", [-97.74306, 30.26715], 100],
  ["Jacksonville, FL", [-81.65565, 30.33218], 100],
  ["Fort Worth, TX", [-97.32085, 32.72541], 100],
  ["San Francisco, CA", [-122.41942, 37.77493], 100],
  ["Columbus, OH", [-82.99879, 39.96118], 100],
  ["Charlotte, NC", [-80.84313, 35.22709], 100],
  ["Indianapolis, IN", [-86.15804, 39.76838], 100],
  ["Seattle, WA", [-122.33207, 47.60621], 75],
  ["Denver, CO", [-104.9847, 39.73915], 100],
  ["Washington, DC", [-77.03637, 38.89511], 100],
  ["El Paso, TX", [-106.48693, 31.75872], 100],
  ["Boston, MA", [-71.05977, 42.35843], 100],
  ["Nashville, TN", [-86.78444, 36.16589], 100],
  ["Detroit, MI", [-83.04575, 42.33143], 100],
  ["Portland, OR", [-122.67621, 45.52345], 100],
  ["Oklahoma City, OK", [-97.51643, 35.46756], 100],
  ["Las Vegas, NV", [-115.13722, 36.17497], 100],
  ["Memphis, TN", [-90.04898, 35.14953], 100],
  ["Louisville, KY", [-85.75941, 38.25424], 100],
  ["Baltimore, MD", [-76.61219, 39.29038], 100],
  ["Milwaukee, WI", [-87.90647, 43.0389], 75],
  ["Albuquerque, NM", [-106.65114, 35.08449], 100],
  ["Tucson, AZ", [-110.92648, 32.22174], 100],
  ["Fresno, CA", [-119.77237, 36.74773], 100],
  ["Sacramento, CA", [-121.4944, 38.58157], 100],
  ["Mesa, AZ", [-111.82264, 33.42227], 100],
  ["Kansas City, MO", [-94.57857, 39.09973], 100],
  ["Atlanta, GA", [-84.38798, 33.749], 100],
  ["Colorado Springs, CO", [-104.82136, 38.83388], 100],
  ["Miami, FL", [-80.19366, 25.77427], 100],
  ["Raleigh, NC", [-78.63861, 35.7721], 100],
  ["Long Beach, CA", [-118.18923, 33.76696], 100],
  ["Virginia Beach, VA", [-75.97799, 36.85293], 100],
  ["Omaha, NE", [-95.94043, 41.25626], 100],
  ["Oakland, CA", [-122.2708, 37.80437], 100],
  ["Minneapolis, MN", [-93.26384, 44.97997], 100],
  ["Tulsa, OK", [-95.99277, 36.15398], 100],
  ["Arlington, TX", [-97.10807, 32.73569], 100],
  ["New Orleans, LA", [-90.07507, 29.95465], 100],
  ["Tampa, FL", [-82.45843, 27.94752], 100],
  ["Wichita, KS", [-97.33754, 37.69224], 100],
  ["Bakersfield, CA", [-119.01871, 35.37329], 100],
  ["Cleveland, OH", [-81.69541, 41.4995], 100],
  ["Aurora, CO", [-104.83192, 39.72943], 100],
  ["Anaheim, CA", [-117.9145, 33.83529], 100],
  ["Honolulu, HI", [-157.85833, 21.30694], 100],
  ["Santa Ana, CA", [-117.86783, 33.74557], 100],
  ["Riverside, CA", [-117.39616, 33.95335], 100],
  ["Corpus Christi, TX", [-97.39638, 27.80058], 100],
  ["Lexington, KY", [-84.45855, 38.0498], 100],
  ["Stockton, CA", [-121.29078, 37.9577], 100],
  ["Henderson, NV", [-114.98194, 36.0397], 100],
  ["St. Paul, MN", [-93.09327, 44.94441], 100],
  ["St. Louis, MO", [-90.19789, 38.62727], 100],
  ["Pittsburgh, PA", [-79.99589, 40.44062], 100],
  ["Cincinnati, OH", [-84.45689, 39.162], 100],
  ["Anchorage, AK", [-149.90028, 61.21806], 100],
  ["Plano, TX", [-96.69889, 33.01984], 100],
  ["Orlando, FL", [-81.37924, 28.53834], 100],
  ["Greensboro, NC", [-79.79198, 36.07264], 100],
  ["Lincoln, NE", [-96.66696, 40.8], 100],
  ["Irvine, CA", [-117.82311, 33.66946], 100],
  ["Newark, NJ", [-74.17237, 40.73566], 100],
  ["Toledo, OH", [-83.55521, 41.66394], 100],
  ["Durham, NC", [-78.89862, 35.99403], 100],
  ["Chula Vista, CA", [-117.0842, 32.64005], 100],
  ["St. Petersburg, FL", [-82.67927, 27.77086], 100],
  ["Fort Wayne, IN", [-85.12886, 41.1306], 100],
  ["Jersey City, NJ", [-74.07764, 40.72816], 100],
  ["Laredo, TX", [-99.50754, 27.50641], 100],
  ["Madison, WI", [-89.40123, 43.07305], 100],
  ["Scottsdale, AZ", [-111.89903, 33.50921], 100],
  ["Lubbock, TX", [-101.85517, 33.57786], 100],
  ["Chandler, AZ", [-111.84125, 33.30616], 100],
  ["Reno, NV", [-119.8138, 39.52963], 100],
  ["Buffalo, NY", [-78.87837, 42.88645], 100],
  ["Glendale, AZ", [-112.18599, 33.53865], 100],
  ["North Las Vegas, NV", [-115.1175, 36.19886], 100],
  ["Gilbert, AZ", [-111.78903, 33.35283], 100],
  ["Winston-Salem, NC", [-80.24422, 36.09986], 100],
  ["Norfolk, VA", [-76.28522, 36.84681], 100],
  ["Chesapeake, VA", [-76.27494, 36.81904], 100],
  ["Irving, TX", [-96.94889, 32.81402], 100],
  ["Fremont, CA", [-121.98857, 37.54827], 100],
  ["Hialeah, FL", [-80.27811, 25.8576], 100],
  ["Garland, TX", [-96.63888, 32.91262], 100],
  ["Richmond, VA", [-77.46026, 37.55376], 100],
  ["Boise City, ID", [-116.20345, 43.6135], 100],
  ["Baton Rouge, LA", [-91.15455, 30.45075], 100],
  ["Spokane, WA", [-117.42908, 47.65966], 100]
].sort((a, b) => a[0].localeCompare(b[0]));
