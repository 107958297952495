import React from "react";
import Image from "../../components/image";

const SecondaryImage = ({ url }) => (
  <div className="be-secondary-image">
    <Image url={url} showPlaceholder={true} />
  </div>
);

const LinkedSecondaryImage = ({ linkUrl, url }) => (
  <a
    className="be-place-details__field-link be-place-details__field-link--theme"
    href={linkUrl}
  >
    <SecondaryImage url={url} />
  </a>
);

const Gallery = ({ images, linkToRT, urlRT }) => (
  <div className="be-photos" data-cy="boone-gallery">
    <div className="be-primary-image be-primary-image--theme">
      <div className="be-primary-image-inner">
        <Image
          url={images && images[0] ? images[0].sizes["square"].url : ""}
          sizes={images && images[0] ? images[0].sizes : { base: "" }}
          showPlaceholder={true}
        />
      </div>
    </div>
    {images && images.length > 1 ? (
      <div className="image-gallery-wrap">
        <div className="be-place-details__section-header be-place-details__section-header--theme">
          Other photos
        </div>
        <div className="image-gallery">
          {images.slice(1).map(({ id, sizes }) => {
            const imgUrl = sizes ? sizes["thumbnail"].url : "";
            return linkToRT ? (
              <LinkedSecondaryImage key={id} url={imgUrl} linkUrl={urlRT} />
            ) : (
              <SecondaryImage key={id} url={imgUrl} />
            );
          })}
        </div>
      </div>
    ) : null}
  </div>
);

export default Gallery;
