import "./styles/base.scss";

const formatImageData = args => {
  if (!args || !args.image_url) {
    return { urls: { base: "" } };
  }

  const { sizes, image_url, source_name, ...otherProps } = args;

  return {
    ...otherProps,
    sourceName: source_name,
    urls: sizes
      ? {
          base: image_url,
          large: sizes.large && sizes.large.url,
          thumbnail: sizes.thumbnail && sizes.thumbnail.url,
          square: sizes.square && sizes.square.url,
          medium: sizes.medium && sizes.medium.url,
          small: sizes.small && sizes.small.url
        }
      : { base: image_url }
  };
};

const formatLocationData = ({ postal_code, ...otherProps }) => {
  if (!otherProps) {
    return {
      formattedAddress: ""
    };
  }

  return {
    city: "",
    state: "",
    postalCode: postal_code,
    ...otherProps,
    formattedAddress: [otherProps.city, otherProps.state, otherProps.country]
      .filter(str => str && str.length > 0)
      .join(", ")
  };
};

export const formatPlaceProps = place => {
  return {
    id: place.id,
    name: place.name,
    description: (place.descriptions[0] && place.descriptions[0].text) || "",
    descriptionSourceName:
      (place.descriptions[0] && place.descriptions[0].source_name) || "",
    subtitle: (place.subtitles[0] && place.subtitles[0].text) || place.subtitle,
    image: formatImageData(place.images[0]),
    location: formatLocationData(place.locations[0]),
    tags: place.tags ? place.tags.map(tag => tag.name) : [],
    contactInfo: { ...place.contact_info },
    socialHandle:
      place.social_handles && place.social_handles[0]
        ? place.social_handles[0]
        : null,
    reviews: place.reviews,
    hours: place.hours,
    activities: place.activities
      ? place.activities.map(activity => activity.name)
      : []
  };
};
