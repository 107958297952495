import React from "react";
import RoadtrippersLogo from "../../roadtrippersLogo";

const SaveIcon = () => (
  <svg
    className="be-button-icon"
    width="20px"
    height="18px"
    viewBox="0 0 20 18"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className="be-place-details__save-button__icon be-place-details__save-button__icon--theme"
        transform="translate(-636.000000, -1781.000000)"
        fillRule="nonzero"
      >
        <g transform="translate(235.000000, 192.000000)">
          <g transform="translate(379.000000, 1577.000000)">
            <g>
              <path d="M24,17.7432582 C24,14.9801786 25.7234286,14 27.2,14 C29.7234286,14 32,17.6837091 32,17.6837091 C32,17.6837091 34.2852571,14 36.8,14 C38.2765714,14 40,14.9801786 40,17.7432582 C40,21.0541897 36.5577143,25.403658 32,28 C27.4422857,25.403658 24,21.0541897 24,17.7432582 Z M36.6666667,12 C34,12 33,13.231875 32,14.57175 C31,13.231875 30,12 27.3333333,12 C24.6666667,12 22,13.92825 22,17.785875 C22,22.285875 26.6666667,27.42825 32,30 C37.3333333,27.42825 42,22.285875 42,17.785875 C42,13.92825 39.3333333,12 36.6666667,12 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ButtonLabel = ({ children }) => (
  <span className="be-button-label"> {children} </span>
);

export const CheckItOutButton = ({ rtUrl }) =>
  rtUrl ? (
    <a
      className="be-place-details__rt-button be-place-details__rt-button--theme"
      href={rtUrl}
      target="_blank"
    >
      <span className="be-button-icon">
        <RoadtrippersLogo fillClass="be-place-details__rt-logo-icon be-place-details__rt-logo-icon--theme" />
      </span>
      <ButtonLabel> Check it out </ButtonLabel>
    </a>
  ) : (
    <div className="be-place-details__rt-button be-place-details__rt-button--theme be-disabled">
      <span className="be-button-icon">
        <RoadtrippersLogo fillClass="be-place-details__rt-logo-icon be-place-details__rt-logo-icon--theme" />
      </span>
      <ButtonLabel> Check it out </ButtonLabel>
    </div>
  );

export const SaveButton = ({ rtUrl }) =>
  rtUrl ? (
    <a
      className="be-place-details__save-button be-place-details__save-button--theme"
      href={rtUrl}
      target="_blank"
    >
      <SaveIcon />
      <ButtonLabel> Save it </ButtonLabel>
    </a>
  ) : (
    <div className="be-place-details__save-button be-place-details__save-button--theme be-disabled">
      <SaveIcon />
      <ButtonLabel> Save this place </ButtonLabel>
    </div>
  );
