import React from "react";
import { render } from "react-dom";
import Explorer from "./explorer/index";
import Astronomer from "./astronomer";
import * as util from "./util";
import {
  PAGE_SIZE,
  VIEW_TYPE_GRID,
  VIEW_TYPE_LIST,
  VIEW_TYPE_MAP
} from "./constants/base";

const CONTAINER_ID = "rt-explorer";

try {
  let container = document.getElementById(CONTAINER_ID);

  if (container) {
    let {
      clientId,
      center,
      radius,
      tags,
      places,
      maxResults,
      limit = PAGE_SIZE,
      view,
      activities,
      country,
      connections = "roadtrippers"
    } = container.dataset;

    if (clientId) {
      // Set up Astronomer
      const astronomer = new Astronomer({
        accessToken: clientId,
        appId: process.env.ASTRONOMER_KEY
      });

      delete container.dataset.clientId;
      astronomer.track("Explorer Loaded");

      // if one is defined, they all must be defined
      const interdependentAttrs = { center, radius, tags };
      const keys = Object.keys(interdependentAttrs);

      const undefinedAttrs = [];
      const definedAttrs = [];

      const all = keys
        .map(key => {
          const set = Boolean(interdependentAttrs[key]);
          const attr = '"data-' + util.camelToHyphen(key) + '"';
          set ? definedAttrs.push(attr) : undefinedAttrs.push(attr);
          return set;
        })
        .reduce((prev, curr) => prev && curr, true);
      const nothing = undefinedAttrs.length === keys.length;

      if (country) {
        if (country !== "us" && country !== "nz" && country !== "ca") {
          country = "us";
          console.error(
            `[Boone Explorer]: Data attribute "data-country" can only be "us", "nz", "ca", or undefined. Defaulting to "us".`
          );
        }
      }

      if (view) {
        if (
          view !== VIEW_TYPE_GRID &&
          view !== VIEW_TYPE_LIST &&
          view !== VIEW_TYPE_MAP
        ) {
          console.error(
            `[Boone Explorer]: Data attribute "data-view" can only be "${VIEW_TYPE_GRID}", "${VIEW_TYPE_LIST}", "${VIEW_TYPE_MAP}", or undefined.`
          );
        }
      }

      // data-places always takes precedence over the interdependent attrs
      if (places && definedAttrs.length > 0) {
        // if places is set, override any values set for the interdependent attrs
        tags = null;
        center = null;
        radius = null;
        console.warn(
          `[Boone Explorer]: Data attributes "data-center", "data-tags", and "data-radius" will be ignored because "data-places" is set on the container element.`
        );
      } else if (!(all || nothing)) {
        // Example Error Message
        // [Boone Explorer]: When data attribute "data-tags" is set on the container element, data attributes "data-center" and "data-radius" are required.
        const definedAttrsStr = util.fmtAdjNounsVerb(
          definedAttrs,
          "data attribute"
        );
        const undefinedAttrsStr = util.fmtAdjNounsVerb(
          undefinedAttrs,
          "data attribute"
        );
        console.error(
          `[Boone Explorer]: When ${definedAttrsStr} set on the container element, ${undefinedAttrsStr} required.`
        );
      }

      render(
        <Explorer
          clientId={clientId}
          astronomer={astronomer}
          showSearch={nothing && !places}
          params={{
            center,
            radius,
            canonical_place_ids: places,
            tags,
            // limit - max results per request, if undefined, defaults to PAGE_SIZE
            limit: parseInt(limit),
            view,
            activities
          }}
          // maxResults - total number of results that should be visible in the ui
          maxResults={maxResults}
          country={country || "us"}
          connections={connections}
        />,
        container
      );
    } else {
      console.error(
        "[Boone Explorer]: Attribute `data-client-id` attribute missing from `#" +
          CONTAINER_ID +
          "` container."
      );
    }
  } else {
    console.error(
      "[Boone Explorer]: Explorer requires an empty `#" +
        CONTAINER_ID +
        "` container."
    );
  }
} catch (e) {
  console.log(e);
}
