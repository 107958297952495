export const CATEGORIES_US = [
  ["National & State Parks", ["national-parks", "state-park"]],
  ["Offbeat Attractions", ["offbeat-attractions"]],
  ["Outdoors & Recreation", ["nature"]],
  ["Points of Interest", ["points-of-interest"]],
  ["Camping & RV", ["camping-rv"]],
  ["Attractions & Culture", ["attractions"]],
  ["Museums", ["museum"]]
];

export const CATEGORIES_CA = [
  ["National & Provincial Parks", ["national-parks", "state-park"]],
  ["Offbeat Attractions", ["offbeat-attractions"]],
  ["Outdoors & Recreation", ["nature"]],
  ["Points of Interest", ["points-of-interest"]],
  ["Camping & RV", ["camping-rv"]],
  ["Attractions & Culture", ["attractions"]],
  ["Museums", ["museum"]]
];

export const CATEGORIES_NZ = [
  ["National & Regional Parks", ["national-parks", "state-park"]],
  ["Offbeat Attractions", ["offbeat-attractions"]],
  ["Outdoors & Recreation", ["nature"]],
  ["Points of Interest", ["points-of-interest"]],
  ["Camping & RV", ["camping-rv"]],
  ["Attractions & Culture", ["attractions"]],
  ["Museums", ["museum"]]
];

export const CATEGORIES = {
  us: CATEGORIES_US,
  ca: CATEGORIES_CA,
  nz: CATEGORIES_NZ
};

export const DEFAULT_CITY_CATEGORY = {
  us: {
    city: 21,
    category: 0
  },
  ca: {
    city: 86,
    category: 0
  },
  nz: {
    city: 1,
    category: 0
  }
};

export const RT_API = "https://api2.roadtrippers.com/api/v1";
export const RT_API_USERS = "/users";

export const BOONE_API = "https://api.boone.ai/api/v2";
export const BOONE_API_CATEGORICAL_PLACES = "/canonical_places";
export const BOONE_API_SEARCH = "/search";

export const RT_MAPS_URL = "https://maps.roadtrippers.com";

export const MILES_TO_METERS = 1609;

export const PAGE_SIZE = 12;

export const VIEW_TYPE_LIST = "list";
export const VIEW_TYPE_GRID = "grid";
export const VIEW_TYPE_MAP = "map";
