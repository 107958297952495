"use strict";
// Adapted from http://docs.astronomer.io/docs/analyticsjs
var ANALYTICS_JS =
  '!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Astronomer snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement("script");e.type="text/javascript";e.async=!0;e.src="https://cdn.metarouter.io/a/v1/"+t+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION="3.1.0";analytics.load("APP_ID");}}();';

function Astronomer(options) {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = ANALYTICS_JS.replace(/APP_ID/, options.appId);
  document.body.appendChild(script);

  this.script = script;
  this.accessToken = options.accessToken;
}

Astronomer.prototype.track = function(event, properties) {
  properties = properties || {};
  properties.accessToken = this.accessToken;

  analytics.track(event, properties);
};

export default Astronomer;
